<template>
	<page-container :page="page">
		<heading-image
			v-if="page.headingImage"
			:heading-image="{
				image: page.headingImage,
				imageWebp: page.headingImageWebp,
				imagePortrait: page.headingImagePortrait,
				imagePortraitWebp: page.headingImagePortraitWebp,
			}"
		/>
		<logo />
		<main-content
			:title="(page.title || page.header) ?? 'Page not found'"
			:subtitle="page.subtitle"
			:content="page.content"
		/>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();

await fetchPage();
</script>
